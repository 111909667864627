import React from "react";
import {createRoot} from "react-dom/client";

import * as logger from './core/logger';

import {Application} from './application';

const root = createRoot(document.getElementById('root'));

(async function bootstrap() {
    try {
        const response = await fetch('./src/env-config.json');

        window.ENV_CONFIG = await response.json();

        root.render(
            <React.StrictMode>
                <Application />
            </React.StrictMode>
        );

        logger.info('[RPA][bootstrap] application mounted');
    } catch (error) {
        logger.error('[RPA][bootstrap] failed to fetch env config', error);
    }
})();
