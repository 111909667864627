import {logger} from './logger';

export class ConnectwiseHostedApi {
    frameID = '';

    trustedOrigin = '*';

    subscribers = {};

    constructor(origins = '*', subscribers = [], isDebugMode = false, mainWindow = window, parentWindow = window.parent) {
        this.origins = origins;
        this.window = mainWindow;
        this.parent = parentWindow;
        this.logger = logger(isDebugMode);

        this.register(subscribers);
    }

    handshake() {
        this.logger.info('[CWHAPI][handshake] starting a handshake...');
        const unsubscribe = this.subscribe();

        this.post({message: 'ready'});

        return unsubscribe;
    }

    emit(event, callback) {
        this.logger.debug('[CWHAPI][emit] emitting a message');
        this.register([{event, callback}]);

        // need to pre-pend hosted_ to request to avoid confusion with vendor hosted api.
        this.post({hosted_request: event});
    }

    handleIncomingMessage(event) {
        const json = JSON.parse(event.data);
        this.logger.debug('[CWHAPI][handleIncomingMessage] incoming data', {json});

        if (json.MessageFrameID) {
            this.handleOnReady(json);

            return;
        }

        if (json.event) {
            this.handleOnEvent(json);

            return
        }

        if (json.response) {
            this.handleOnResponse(json);

            return;
        }

        this.logger.warn('[CWHAPI][handleIncomingMessage] incoming event has been skipped', {json});
        this.post({event: json.event, _id: json._id, result: 'success'});
    }

    handleOnReady(message) {
        this.frameID = message.MessageFrameID;

        this.logger.debug('[CWHAPI][handleOnReady] frameID has been set', {frameID: this.frameID});
    }

    handleOnEvent(message) {
        const subscriber = this.getSubscriber(message.event);

        if (!subscriber) {
            this.logger.warn('[CWHAPI][handleOnEvent] subscriber not found', {event: message.event});

            return;
        }

        subscriber(message.data, {
            onSuccess: () => this.post({
                event: message.event,
                _id: message._id,
                result: 'success',
            }),
            onFailure: (data) => this.post({
                event: message.event,
                _id: message._id,
                result: 'failure',
                errors: data,
            })
        });

        this.logger.debug('[CWHAPI][handleOnEvent] subscriber has been called', {message});
    }

    handleOnResponse(message) {
        const subscriber = this.getSubscriber(message.response);

        if (!subscriber) {
            this.logger.warn('[CWHAPI][handleOnResponse] subscriber not found', {response: message.response});

            return;
        }

        subscriber(message.data);

        this.logger.debug('[CWHAPI][handleOnResponse] subscriber has been called', {message});

        // we need to unregister our subscriber on response just because it's working as one time callback
        // e.g. instance.emit('getMemberAuthentication', () => {});
        this.unregister(message.response);
    }

    register(subscribers) {
        this.logger.debug('[CWHAPI][register] registering subscribers', subscribers);

        if (!Array.isArray(subscribers)) {
            throw new Error('parameter `subscribers` should be an array');
        }

        for (const {event, callback} of subscribers) {
            if (!event || typeof callback !== 'function') {
                this.logger.error(`[CWHAPI][register] given "event" ${event} or "callback" ${typeof callback} params expecting a value`, {event});

                return;
            }

            if (this.subscribers[event.toLowerCase()]) {
                this.logger.warn('[CWHAPI][register] attempting to register subscriber to the already existing event', {event});

                return;
            }

            this.subscribers[event.toLowerCase()] = callback;

            this.logger.debug('[CWHAPI][register] subscriber has been registered', {events: Object.keys(this.subscribers)});
        }
    }

    unregister(event) {
        if (!this.subscribers[event.toLowerCase()]) {
            this.logger.warn('[CWHAPI][unregister] attempt ot unregister subscriber from non existing event', {event});

            return;
        }

        delete this.subscribers[event.toLowerCase()];

        this.logger.debug('[CWHAPI][unregister] subscriber has been unregistered', {events: Object.keys(this.subscribers)});
    }

    subscribe() {
        const handler = (event) => {
            this.logger.debug('[CWHAPI][subscribe] received message event', {
                origin: event.origin,
                data: event.data
            }, event);

            if (!this.isOriginTrusted(event.origin)) {
                this.logger.warn('[CWHAPI][subscribe] untrusted origin', {
                    origin: event.origin,
                    trustedOrigin: this.trustedOrigin,
                    origins: this.origins
                });

                return;
            }

            if (event.origin) {
                this.trustedOrigin = event.origin;
            }

            this.logger.debug('[CWHAPI][subscribe] trusted origin has been set', {trustedOrigin: this.trustedOrigin});

            this.handleIncomingMessage(event);
        };

        this.window.addEventListener('message', handler, false);
        this.logger.debug('[CWHAPI][subscribe] message listener has been added');

        return () => {
            window.removeEventListener('message', handler);
            this.logger.debug('[CWHAPI][subscribe] message listener has been removed');
        }
    }

    post(data) {
        const message = {...data, ...(this.frameID ? {frameID: this.frameID} : {})};
        const envelope = JSON.stringify(message);

        this.parent.postMessage(envelope, this.trustedOrigin);

        this.logger.debug('[CWHAPI][post] message has been posted', {message, envelope});
    }

    getSubscriber(event) {
        return this.subscribers[event.toLowerCase()];
    }

    isOriginTrusted(origin) {
        if (this.origins === '*') {
            return true;
        }

        if (Array.isArray(this.origins)) {
            return this.origins.some(item => item === origin);
        }

        return false;
    }
}
