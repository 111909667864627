import {logger} from './logger';

export class ManageIntegration {
    subscribers = {};

    timeout = 0;

    token = {
        access: '',
        ttl: 0,
    };

    ticket = {
        id: '',
        site: '',
        companyId: '',
    };

    constructor(hostedApiInstance, isDebugMode = false) {
        this.instance = hostedApiInstance;
        this.logger = logger(isDebugMode);
    }

    connect() {
        this.logger.info('[MI][connect] init connection');

        this.instance.register([{event: 'onLoad', callback: this.handleOnLoad}]);

        const destroy = this.instance.handshake();

        return () => {
            this.logger.debug('[MI][connect] destroying connection');
            destroy();

            this.subscribers = {};
            this.token = {
                access: '',
                ttl: 0,
            };
            this.ticket = {
                id: '',
                site: '',
                companyId: '',
            };

            clearTimeout(this.timeout);

            this.timeout = 0;

            this.logger.debug('[MI][connect] state has been restored', {
                timeout: this.timeout,
                subscribers: Object.keys(this.subscribers),
                token: this.token,
                ticket: this.ticket
            });
        }
    }

    on(event, callback) {
        if (!event || typeof callback !== 'function') {
            this.logger.error('[MI][on] received unexpected arguments', {event, callback: typeof callback});

            return;
        }

        this.subscribers[event] = callback;

        this.logger.debug('[MI][on] subscriber has been set', {event});

        return () => {
            delete this.subscribers[event];

            this.logger.debug('[MI][on] subscriber has been deleted', {event});
        }
    }

    emit(event, data) {
        const subscriber = this.subscribers[event];

        if (!subscriber) {
            this.logger.warn('[MI][emit] subscriber not found', {event, data});

            return;
        }

        subscriber(data);

        this.logger.debug('[MI][emit] subscriber has been called', {event, data});
    }

    handleOnLoad = (data_) => {
        this.logger.debug('[MI][handleOnLoad] received data', {data: data_});

        const {screenObject} = data_;

        this.ticket.id = screenObject.id;

        this.instance.emit('getMemberAuthentication', (data) => {
            this.logger.debug('[MI][on getMemberAuthentication] response', {data});

            const token = this.getToken(data);
            const ttl = this.getTokenTTL(token);

            this.logger.debug('[MI][on getMemberAuthentication] token and ttl', {token, ttl});

            this.ticket.site = data.site;
            this.ticket.companyId = data.companyid;

            if (ttl === 0) {
                this.requestRefreshToken(() => {
                    this.logger.success('[MI][on getMemberAuthentication] connection established', {
                        token: this.token,
                        ticket: this.ticket
                    });

                    this.emit('connect', {token: this.token.access, ticket: this.ticket});
                });

                return;
            }

            this.token.access = token;
            this.token.ttl = ttl;

            this.logger.success('[MI][on getMemberAuthentication] connection established', {token: this.token, ticket: this.ticket});

            this.emit('connect', {token: this.token.access, ticket: this.ticket});

            this.refreshTokenWhenExpired(ttl);
        });
    }

    requestRefreshToken(callback) {
        this.logger.debug('[MI][requestRefreshToken] requesting refresh token');

        this.instance.emit('refreshssoaccess', (data) => {
            const token = this.getToken(data);
            const ttl = this.getTokenTTL(token);

            this.logger.debug('[MI][on refreshssoaccess] token and ttl', {token, ttl});

            if (ttl === 0) {
                this.logger.warn('[MI][on refreshssoaccess] token expired');

                this.token.access = '';
                this.token.ttl = 0;

                this.emit('token-expired');

                return;
            }

            this.token.access = token;
            this.token.ttl = ttl;

            callback();

            this.refreshTokenWhenExpired(ttl);
        });
    }

    refreshTokenWhenExpired(ttl) {
        this.logger.debug('[MI][refreshTokenWhenExpired] setting timeout', {ttl});

        this.timeout = setTimeout(() => {
            this.requestRefreshToken(() => {
                this.logger.debug('[MI][refreshTokenWhenExpired] token has been refreshed', {
                    token: this.token,
                    ticket: this.ticket
                });

                this.emit('token-refresh', {token: this.token.access, ticket: this.ticket});
            });
        }, ttl);
    }

    getTokenTTL(token) {
        if (!token) {
            return 0;
        }

        // parse JWT in format {head}.{payload}.{signature}
        // `exp` - field that contains time in seconds
        // so therefor we need to convert seconds into milliseconds
        const {exp} = JSON.parse(atob(token.split('.')[1]));
        // todo: investigate if we need to refresh token sooner or later
        const now = new Date().getTime();
        // if time is negative then token is expired
        const time = new Date(exp * 1000).getTime() - now;

        return time > 0 ? time : 0;
    }

    getToken(data) {
        if (!data.ssoAccessToken) {
            return;
        }

        try {
            // found that here
            // https://gitlab.connectwisedev.com/platform/ticket-sentiment-ui/-/blob/main/public/src/scripts/custom.js#L41
            // the issue is that token that coming from event "getMemberAuthentication" is a string
            // but token coming from "refreshssoaccess" it's a string but stringified
            // we can try/catch JSON.parse, but this solution is less "dangerous"
            const isTokenStringified = data.ssoAccessToken.startsWith('"') && data.ssoAccessToken.endsWith('"');

            return isTokenStringified ? JSON.parse(data.ssoAccessToken) : data.ssoAccessToken;
        } catch(error) {
            this.logger.error('[MI][getToken]', error);
        }
    }
}
