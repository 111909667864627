import {MicroUiLoader} from "micro-ui-loader";

import {useManage, getEnvironmentType} from './core';

export function Application() {
    const [{token, ticket, isSessionExpired}] = useManage();

    if (!token) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: '12px'
            }}>
                <div>Chat service handshaking</div>
            </div>);
    }

    if (isSessionExpired) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: '12px'
            }}>
                <div>Session has been expired. Please refresh page</div>
            </div>);
    }

    const tokenData = {
        tokenType: !token && window.iPlanetDirectoryPro ? 'openAM' : 'cwSSO',
        token: window.iPlanetDirectoryPro ? window.iPlanetDirectoryPro : token,
        environment: getEnvironmentType()
    };


    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '12px'}}>
            <MicroUiLoader
                divId="robotic-process-automation-ui"
                packageKey="rpa-ui"
                tokenData={tokenData}
                uiData={{
                    ticket,
                    ticketId: ticket.id,
                    ssoToken: token
                }}
            />

            <div id="robotic-process-automation-ui" style={{height: '100%', flexGrow: 1}}/>
        </div>
    );
}
