const styles = {
    common: `
      font-family: system-ui;
      padding: 2px 6px;
      font-size: 13px;
      border-radius: 2px;
      font-weight: 600;
      letter-spacing: 2px;
      border: 1px dotted;
    `,
    error: 'background: #ffcccc; color: #c70039; border-color: #ffcccc;',
    debug: 'background: #353535; color: #e5e5e5; border-style: solid; border-width: 0px;',
    info: 'background: #0080ff; color: #e5f2ff; border-color: #0080ff;',
    success: 'background: #b2d8b2; color: #008000; border-color: #b2d8b2;',
    warn: 'background: #ffa600; color: black; border-color: orange;'
};

const noop = () => {};

function log(type, title, ...args) {
    console.groupCollapsed('%c%s', `${styles.common}${styles[type]}`, title);
    if (args.length !== 0) {
        console.log(...args);
    }
    console.groupEnd();
}

export const error = log.bind(null, 'error');
export const info = log.bind(null, 'info');
export const success = log.bind(null, 'success');
export const warn = log.bind(null, 'warn');

export function logger(isDebugEnabled = false) {
    return {
        error,
        debug: isDebugEnabled ? log.bind(null, 'debug') : noop,
        info,
        success,
        warn,
    }
}
